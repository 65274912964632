
import { defineComponent } from "vue";
import Preloader from "@/components/ui/Preloader.vue";
import TestimonialText from "@/components/TestimonialText.vue";
import gtmAuthPlace from "@/mixins/gtmAuthPlace";

export default defineComponent ({
  name: "Testimonial",
  components: {
    TestimonialText,
    Preloader,
  },
  mixins: [gtmAuthPlace],
  computed: {
    //@ts-ignore
    testimonial() {
      //@ts-ignore
      const testimonialId = this.$route.params.id;
      //@ts-ignore
      return this.$store.getters['testimonialsList/testimonialBySlug'](testimonialId);
    },
  },
  methods: {
    onBottomButton() {
      this.gtmTrackAndSave('signup', 'Hero Banner');
      //@ts-ignore
      window.location.href = 'https://allstars.gifted.co/';
    }
  },
  created() {
    if (this.$store.getters['testimonialsList/testimonialsList'].length === 0) {
      this.$store.dispatch('testimonialsList/getTestimonialsList');
    }
  }
})
