
import { defineComponent } from "vue";

export default defineComponent ({
  name: "TestimonialText",
  props: {
    quote: {
      type: String,
      default: ""
    }
}})
